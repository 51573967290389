import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import {
  FoundationCard,
  FoundationBody,
  FoundationFooter,
  FoundationFigure,
  FoundationName,
  FoundationDesc,
} from "../ProductStyle";


const FoundationGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  @media (min-width: 1600px) {
    margin: 0 -15px;
  }
`;
const FoundationGridItem = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 768px) {
    max-width: 50%;
    flex: 0 0 50%;
  }
  @media (min-width: 1200px) {
    max-width: 25%;
    flex: 0 0 25%;
  }
  @media (min-width: 1600px) {
    padding: 0 15px;
  }
`;


const FoundationCardGrid = () => {
  return (
    <FoundationGrid>
        <FoundationGridItem>
            <FoundationCard className="card card-foundation">
            <FoundationBody className="card-body">
                <FoundationFigure className="card-figure">         
                    <StaticImage src="../../images/ground-foundation.jpg" alt="Ground Foundation" />       
                </FoundationFigure>
            </FoundationBody>
            <FoundationFooter className="card-footer">
                <FoundationName className="product-name">Ground Foundation</FoundationName>
                <FoundationDesc className="product-desc"><p>This is the simplest type, involving compacting the soil to create a firm base. It’s suitable for smaller structures that don’t require heavy load support.</p></FoundationDesc>
            </FoundationFooter>
            </FoundationCard>
        </FoundationGridItem>
        <FoundationGridItem>
            <FoundationCard className="card card-foundation">
            <FoundationBody className="card-body">
                <FoundationFigure className="card-figure">         
                    <StaticImage src="../../images/gravel-foundation.jpg" alt="Gravel Foundation" />       
                </FoundationFigure>
            </FoundationBody>
            <FoundationFooter className="card-footer">
                <FoundationName className="product-name">Gravel Foundation</FoundationName>
                <FoundationDesc className="product-desc"><p>A layer of gravel is spread and compacted. This type of foundation provides excellent drainage and is ideal for areas with heavy rainfall.</p></FoundationDesc>
            </FoundationFooter>
            </FoundationCard>
        </FoundationGridItem>
        <FoundationGridItem>
            <FoundationCard className="card card-foundation">
            <FoundationBody className="card-body">
                <FoundationFigure className="card-figure">         
                    <StaticImage src="../../images/asphalt-foundation.jpg" alt="Asphalt Foundation" />       
                </FoundationFigure>
            </FoundationBody>
            <FoundationFooter className="card-footer">
                <FoundationName className="product-name">Asphalt Foundation</FoundationName>
                <FoundationDesc className="product-desc"><p>Asphalt can be used to create a smooth, durable surface. It's less common for entire building foundations but can be suitable for pathways or partial areas under your metal structure.</p></FoundationDesc>
            </FoundationFooter>
            </FoundationCard>
        </FoundationGridItem>
        <FoundationGridItem>
            <FoundationCard className="card card-foundation">
            <FoundationBody className="card-body">
                <FoundationFigure className="card-figure">         
                    <StaticImage src="../../images/concrete-foundation.jpg" alt="Concrete Foundation" />       
                </FoundationFigure>
            </FoundationBody>
            <FoundationFooter className="card-footer">
                <FoundationName className="product-name">Concrete Foundation</FoundationName>
                <FoundationDesc className="product-desc"><p>The most robust type of foundation, concrete slabs provide a solid, level base that supports heavier loads. This foundation is highly recommended for large metal buildings and those that house heavy equipment.</p></FoundationDesc>
            </FoundationFooter>
            </FoundationCard>
        </FoundationGridItem>
    </FoundationGrid>
  );
};
export default FoundationCardGrid;
